import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { NavController } from '@ionic/angular';

import { Project } from '../../models/Project';
import { ProjectService } from '../../providers/project-service/project-service';
import { AuthService } from '../../providers/auth-service/auth-service';
import { NotificationService } from 'src/app/providers/notification-service/notification-service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.page.html',
  styleUrls: ['./create-project.page.scss'],
})
export class CreateProjectPage implements OnInit {

  project: Project;
  loading: boolean = false;

  initialCheck: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private projectService: ProjectService,
    private toastController: ToastController,
    private notificationService: NotificationService,
    private auth: AuthService,
  public navCtrl: NavController) { }

  ngOnInit() {

    this.project = {
      name: '',
      description: '',
      icon: '',
      owner: [this.auth.currentUser.uid],
      integrations: [],
      team: [this.auth.currentUser.uid],
    } as Project;

    this.projectService.getPlatformStatus().then((status)=>{
      if(status.projects){
        this.initialCheck = true;
      } else {
        this.dismiss();
        this.notificationService.addNotification("Workspace Erstellung nicht möglich. Bitte kontaktiere den Support.","system-error");
      }
    });

  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

  handleEmoji(e){
    this.project.icon = e.char;
  }

  createProject(){
    this.loading = true;

    this.projectService.getPlatformStatus().then((status)=>{
      if(status.projects){

        this.projectService.addProject(this.project).then((result)=>{
          this.navCtrl.navigateForward('/'+result.id+'/setup', {
            animated: false
          });
          this.projectService.setCurrentProject(result.id).then((done)=>{
            this.loading = false;
            this.dismiss();
          });
        });

      } else {
        this.dismiss();
        this.notificationService.addNotification("Workspace Erstellung nicht möglich. Bitte kontaktiere den Support.","system-error");
      }
    });

  }

}
