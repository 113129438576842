import { Injectable, Inject } from '@angular/core';
import { TranslationBridge } from '../translation-bridge/translation-bridge';
import { ProjectService } from '../project-service/project-service';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth-service/auth-service';
import { Webhook } from 'src/app/models/Webhook';

const API_KEY_SCOPES = ['ACCOUNTS', 'TRANSACTIONS'];

@Injectable({
    providedIn: 'root'
})
export class WebhookService {

  constructor(
    private projectService: ProjectService,
    private translate: TranslationBridge,
    private http: HttpClient,
    private authService: AuthService
  ){

  }

  public addWebhook(webhook: Webhook): Promise<Webhook>{
    return new Promise((resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");

      if(!project.webhooks) project.webhooks = [];

      // generate id if missing 
      if(!webhook.id) webhook.id = new Date().getTime().toString();

      // some defaults
      if(!webhook.active) webhook.active = false;
      if(!webhook.createdAt) webhook.createdAt = new Date();
      if(!webhook.lastUsedAt) webhook.lastUsedAt = null;
      if(!webhook.scope) webhook.scope = 'TRANSACTIONS';
      if(!webhook.accounts) webhook.accounts = [''];
      if(!webhook.createdBy) webhook.createdBy = this.authService.currentUser.uid;

      // put it to the beginning of webhooks
      if(!project.webhooks) project.webhooks = [];
      project.webhooks.unshift(webhook);

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(webhook);
      }).catch((err) => {
        return reject(err);
      });
    });
  }

  public updateWebhook(webhook: Webhook): Promise<Webhook>{
    return new Promise((resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");

      if(!project.webhooks) return reject("No Webhooks found");

      let index = project.webhooks.findIndex((int) => {
        return int.id == webhook.id;
      });

      if(index == -1) return reject("Integration not found");

      project.webhooks[index] = webhook;

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(project.webhooks[index]);
      }).catch((err) => {
        return reject(err);
      });   
    });
  }

  public deleteWebhook(webhookId: string): Promise<boolean>{
    return new Promise((resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");

      if(!project.webhooks) return reject("No API keys found");

      let index = project.webhooks.findIndex((int) => {
        return int.id == webhookId;
      });

      if(index == -1) return reject("Integration not found");

      project.webhooks.splice(index,1);

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(true);
      }).catch((err) => {
        return reject(err);
      });   
    });
  }

  public activateWebhook(webhookId: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");
      let index = project.webhooks.findIndex((int) => {
        return int.id == webhookId;
      });

      if(index == -1) return reject("API Key not found");

      project.webhooks[index].active = true;

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(true);
      }).catch((err) => {
        return reject(err);
      });
    });
  }

  public deactivateWebhook(webhookId: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");
      let index = project.webhooks.findIndex((int) => {
        return int.id == webhookId;
      });

      if(index == -1) return reject("API Key not found");

      project.webhooks[index].active = false;

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(true);
      }).catch((err) => {
        return reject(err);
      });
    });
  }

  public getScopes(opts: any = {}): Array<any>{
    if(!opts || !opts.asOptions) return API_KEY_SCOPES;

    let scopes = API_KEY_SCOPES.map(scope => {
      return {
        value: scope,
        title: this.translate.get("WEBHOOK_SCOPE_" + scope.toUpperCase().trim()),
        subtitle: this.translate.get("WEBHOOK_SCOPE_" + scope.toUpperCase().trim() + "_INFO"),
        clearOthers: (scope == 'ALL' ? true : false),
      }
    });
    return scopes;
  }

}
