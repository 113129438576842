import { Component, Input, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Transaction } from 'src/app/models/Transaction';
import { ProjectService } from 'src/app/providers/project-service/project-service';
import { TransactionService } from 'src/app/providers/transaction-service/transaction-service';
import { TranslationBridge } from 'src/app/providers/translation-bridge/translation-bridge';

@Component({
  selector: 'transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
})
export class TransactionListComponent  implements OnInit {

  _transactions: Transaction[] = [];
  @Input() set transactions(transactions: Transaction[]) {
    this._transactions = transactions;
  }
  get transactions() {
    return this._transactions;
  }

  @Input() enableFilterBar: boolean = false;
  _filter: string = '';
  @Input() set filter(filter: string) {
    this._filter = filter;
  }
  get filter() {
    return this._filter;
  }
  
  _fields: string[] = [];
  @Input() set fields(fields: string[]) {
    this._fields = fields;
  }
  get fields() {
    return this._fields;
  }

  _categoryOptions = [];

  constructor(
    private translate: TranslationBridge,
    private toastController: ToastController,
    private transactionService: TransactionService,
    private projectService: ProjectService
    ) { }

  ngOnInit() {

    if(!this.fields || this.fields.length === 0) {
      this.fields = [
        'amount',
        'purpose',
        'bankBookingDate',
        'counterpartName',
        'counterpartIban',
        'category',
      ];
    }

  }

  ngAfterViewInit() {
  
    let projectId = this.projectService.getCurrentProjectId();
    this.transactionService.getCategoriesAsOptions(projectId).then((categories) => {
      this._categoryOptions = categories;
    });
  }

  getFieldTranslation(field: string) {
    const key = field.replace(/([A-Z])/g, '_$1').toUpperCase();
    return this.translate.get(`TRANSACTION_FIELD_${key}`);
  }

  getFieldWidth(field: string) {
    if(field === 'amount') {
      return '100px';
    }
    if(field === 'valueDate') {
      return '160px';
    }
    if(field === 'counterpartName') {
      return '220px';
    }
    if(field === 'counterpartIban') {
      return '220px';
    }
    if(field === 'category') {
      return '180px';
    }
    if(field === 'purpose') {
      return '360px';
    }
    return '220px';
  }

  copyValue(value: any){
    navigator.clipboard.writeText(value).then(() => {
      this.toastController.create({
        message: 'Copied to clipboard!',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    }).catch((err) => {
      this.toastController.create({
        message: 'Couldn\'t copy to clipboard.',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    });
  }



}
