import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth-service/auth-service';
import { Institution } from 'src/app/models/Institution';
import { ConnectedInstitution } from 'src/app/models/ConnectedInstitution';
import { ModalController } from '@ionic/angular';
import { ConnectBankAccountPage } from 'src/app/pages/connect-bank-account/connect-bank-account.page';
import { Account } from 'src/app/models/Account';

interface InstitutionListResult {
  banks: Institution[];
  pagination: {
    total: number;
    page: number;
    perPage: number;
  }
}

interface ConnectedInstitutionListResult {
  connections: ConnectedInstitution[];
  pagination: {
    total: number;
    page: number;
    perPage: number;
  }
}

interface AccountListResult {
  accounts: any[];
  pagination: {
    total: number;
    page: number;
    perPage: number;
  }
}

@Injectable({
providedIn: 'root'
})
export class InstitutionService {

  cachedAccounts: any[] = [];
 
  constructor(private http: HttpClient, private authService: AuthService, private modalController: ModalController) {
    
  }

  /*
    Get all institutions
  */
  async getInstitutions(): Promise<InstitutionListResult> {
    let token = await this.authService.getAuthToken();
    let headers = {
        'Authorization': token
    };
    return this.http.get(environment.systemAPI + '/institutions', {headers: headers}).toPromise() as Promise<InstitutionListResult>;
  } 

  /*
    Get institution by id
  */
  async getInstitutionById(id): Promise<Institution> {
    let token = await this.authService.getAuthToken();
    let headers = {
        'Authorization': token
    };
    return this.http.get(environment.systemAPI + '/institutions/' + id, {headers: headers}).toPromise() as Promise<Institution>;
  }

  /*
    search a institution by name or something
  */
  async searchInstitution(search): Promise<InstitutionListResult> {
    let token = await this.authService.getAuthToken();
    let headers = {
        'Authorization': token
    };
    return this.http.get(environment.systemAPI + '/institutions/search?q=' + search, {headers: headers}).toPromise() as Promise<InstitutionListResult>;
  }

  /*
    create a import request
  */
  async createImportRequest(institutionId, projectId): Promise<any> {
    let token = await this.authService.getAuthToken();
    let headers = {
        'Authorization': token
    };
    return this.http.post(environment.systemAPI + '/' + projectId + '/institutions/import/'+institutionId, {
      institutionId: institutionId,
      projectId: projectId
    }, {headers: headers}).toPromise() as Promise<any>;
  }

  async getImportStatus(institutionId: number, projectId: string, webformId: string): Promise<any> {
    let token = await this.authService.getAuthToken();
    let headers = {
        'Authorization': token
    };
    return this.http.get(environment.systemAPI + '/' + projectId + '/institutions/import/'+institutionId+'/status/'+webformId, {headers: headers}).toPromise() as Promise<any>;
  }

  /*
    create a update connection request
    two ways: a silent way, which might just fail in background
    or a user way, which will return a webform
  */
    async updateInstitutionConnection(institutionId, projectId, background: boolean = false): Promise<any> {
      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };

      if(!background){
        return this.http.post(environment.systemAPI + '/' + projectId + '/institutions/update/'+institutionId, {
          connectionId: institutionId,
          projectId: projectId
        }, {headers: headers}).toPromise() as Promise<any>;
      } else {
        return this.http.post(environment.systemAPI + '/' + projectId + '/institutions/backgroundUpdate/'+institutionId, {
          connectionId: (institutionId ? institutionId : null),
          projectId: projectId,
        }, {headers: headers}).toPromise() as Promise<any>;
      }
    }
  

  /*
    get connected institutions
  */
  async getConnectedInstitutions(projectId: string): Promise<ConnectedInstitutionListResult> {
    let token = await this.authService.getAuthToken();
    let headers = {
        'Authorization': token
    };
    return this.http.get(environment.systemAPI + '/' + projectId + '/institutions/connected', {headers: headers}).toPromise() as Promise<ConnectedInstitutionListResult>;
  }

  /*
    get connected institution by Id
  */
    async getConnectedInstitutionById(connectionId: number,projectId: string): Promise<ConnectedInstitutionListResult> {
      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };
      return this.http.get(environment.systemAPI + '/' + projectId + '/institutions/connected/'+connectionId, {headers: headers}).toPromise() as Promise<ConnectedInstitutionListResult>;
    }

  /*
    get connected accounts as options
  */
  async getAccountsAsOptions(projectId: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      if (this.cachedAccounts || this.cachedAccounts.length == 0) {
        this.getAccounts(projectId).then((result) => {
          let accounts = [];
          result.accounts.forEach((account) => {
            accounts.push({
              title: (account.accountName ? account.accountName : "Bankkonto"),
              subtitle: this.formatIban(account.iban),
              classSubtitle: "is-sensitive is-iban",
              value: account.id
            });
          });
          return resolve(accounts);
        }
        ).catch((error) => {
          return reject(error);
        });
      } else {
        let accounts = [];
        this.cachedAccounts.forEach((account) => {
          accounts.push({
            title: account.accountName,
            subtitle: this.formatIban(account.iban),
            value: account.id
          });
        });
        return resolve(accounts);
      }
    });
  }
  /*
    get active webform from task list
  */
  public getUpdateWebformFromAccounts(accounts: Account[]): any {

      let tasks = [];
      
      accounts.forEach(account => {
        tasks = tasks.concat(account.tasks);
      });

      // get the first task which is a webform and not opened yet
      let webformId = "";
      tasks.forEach(task => {
        if(task.status == "WEB_FORM_REQUIRED" 
        && task.payload.webForm.status == "NOT_YET_OPENED"
        && task.type == "BANK_CONNECTION_UPDATE" ){
          webformId = task.payload.webForm.id;
        }
      });

      return webformId;
  }

  /*
    Get Tasks from all Institutions
  */
  public getWebformTasks(projectId: string): Promise<Array<any>> {
    return new Promise<Array<any>>(async (resolve, reject) => {
      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };

      let urlParams = new URLSearchParams();
      urlParams.append('order', 'createdAt,desc');
      urlParams.append('perPage', '500');

      return this.http.get(environment.systemAPI + '/' + projectId + '/institutions/tasks?'+urlParams.toString(), {headers: headers}).toPromise().then((result: any) => {
        if(result && result.items && result.items.length > 0){
          return resolve(result.items);
        }
        return resolve([]);
      });
    });
  }

  public getWebform(projectId: string, webformId: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };
      return this.http.get(environment.systemAPI + '/' + projectId + '/webforms/' + webformId, {headers: headers}).toPromise().then((result: any) => {
        return resolve(result);
      });
    });
  }

  /*
    delete institution by id
  */
    async deleteConnectedInstitution(connectionId, projectId): Promise<any> {
      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };
      return this.http.delete(environment.systemAPI + '/' + projectId + '/institutions/connected/' + connectionId, {headers: headers}).toPromise() as Promise<any>;
    }
  /*
    delete a account by id
  */
 async deleteConnectedAccount(accountId, projectId): Promise<any> {
    let token = await this.authService.getAuthToken();
    let headers = {
        'Authorization': token
    };
    return this.http.delete(environment.systemAPI + '/' + projectId + '/accounts/connected/' + accountId, {headers: headers}).toPromise() as Promise<any>;
  }

  /*
    get accounts 
  */
  async getAccounts(projectId: string): Promise<AccountListResult> {
    let token = await this.authService.getAuthToken();
    let headers = {
        'Authorization': token
    };
    return this.http.get(environment.systemAPI + '/' + projectId + '/accounts/connected', {headers: headers}).toPromise() as Promise<AccountListResult>;
  }

  /*
    get oudated accounts
  */
  async getOutdatedAccounts(projectId: string): Promise<Account[]> {
    return new Promise<Account[]>((resolve, reject) => {
      let outdatedAccounts = this.getOutdatedAccountsFromList(this.cachedAccounts);
    return resolve(outdatedAccounts);
    });
  };
  getOutdatedAccountsFromList(accounts: Account[]): Account[] {
    return accounts.filter((account: any) => { 
      return account.interfaces?.find( (intf: any) => intf.status == "DOWNLOAD_FAILED" || !account.isAuth );
    });
  }

  /*
    Check if connectedInstitution is outdated
  */
  public isOutdatedConnection(connectedInstitution: ConnectedInstitution): boolean {
    
    let mainInterface = connectedInstitution.interfaces[0];

    if(!mainInterface) return true;    

    let aisConsentActive = false;
    let userActionRequired = false;
    connectedInstitution.interfaces.forEach((intf) => { // check for fucked consent
        if(intf.aisConsent){
            if(intf.aisConsent.status == "NOT_PRESENT"){
                aisConsentActive = false;
            } else if(intf.aisConsent.status == "PRESENT"){
                aisConsentActive = true;
            }
        }
        // @ts-ignore
        if(!userActionRequired && intf.userActionRequired == true) userActionRequired = true;
    });

    if(!aisConsentActive || userActionRequired) return true;
    
    // any account thats outdated?
    if(connectedInstitution.accounts) {
      let outdated = this.getOutdatedAccountsFromList(connectedInstitution.accounts);
      if(outdated.length > 0) {
        return true;
      }
    }

    return false;
  }

  /*
    Connect Bank Account Modal
  */
  public async openConnectBankAccountModal(opts: any = {}) {
    
    let webformId = opts.webformId || "";

    const modal = await this.modalController.create({
      component: ConnectBankAccountPage,
      cssClass: 'large-modal bank-connect-modal',
      componentProps: {
        webformId: webformId
      }
    });
    await modal.present();

  }

  /*
    Update Bank Account Modal
  */
    public async openUpdateBankAccountModal(connectionId: number, opts: any = {}) {
    
      let webformId = opts.webformId || "";
      
      const modal = await this.modalController.create({
        component: ConnectBankAccountPage,
        cssClass: 'large-modal bank-connect-modal',
        componentProps: {
          webformId: webformId,
          mode: 'update',
          connectionId: connectionId
        }
      });
      await modal.present();

      return new Promise((resolve, reject) => {
        modal.onDidDismiss().then((result) => {
          if(result && result.data && result.data.success) {
            return resolve(result.data);
          } else {
            return reject(result);
          }
        });
      });
    }

  public formatIban(str: string): string {
    let after = 4;
    let c = " ";
    var v = str.replace(/[^\dA-Z]/g, ''),
        reg = new RegExp(".{" + after + "}", "g");
    return v.replace(reg, function (a) {
        return a + c;
    }).replace(/[^0-9]+$/, "");
  }

}
